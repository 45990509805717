

let burgerMenu = document.querySelector('.burger-menu-wrap');


burgerMenu.addEventListener('click', () => {
    burgerMenu.classList.toggle('active');
});

document.addEventListener("DOMContentLoaded", function () {
    const contactForm = document.getElementById("contactForm");
    const contactFormWrapper = document.getElementById("contactFormWrapper");
    const responseMessage = document.getElementById("responseMessage");
    const contactUsButton = document.querySelector('.btn-submit');

    contactForm.addEventListener("submit", function (event) {
        event.preventDefault();

        contactUsButton.classList.toggle('disabled');

        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;
        
        const formData = {
            name: name,
            email: email,
            message: message,
        };

        const apiEndpoint = "/api/contactUs";

        fetch(apiEndpoint, {
            method: "POST",
            headers: {
                accept: 'application/json',
                'Content-Type':'application/json;charset=utf-8',
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json(); // This only gets called if the response is successful (status 2xx)
            })
            .then(data => {
                contactFormWrapper.innerHTML = `<p class="success-message">Thank you! We will contact you shortly.</p>`;
                contactFormWrapper.classList.add("success", "show"); // Add 'show' class to make the message visible
            })
            .catch(error => {
                console.error("Error:", error);
                responseMessage.innerHTML = `<p>Error submitting the form. Please try again later.</p>`;
                responseMessage.classList.add("error", "show"); // Add 'show' class to make the message visible

                setTimeout(() => {
                    contactUsButton.classList.remove("disabled");
                    responseMessage.classList.remove("error", "show");
                    responseMessage.classList.add("hide");
                    responseMessage.innerHTML = "Response message placeholder";
                }, 3000);
            });
    });
});
